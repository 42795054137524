import React, {useEffect, useState} from 'react';
import { createClient } from 'contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import Accordion from 'react-bootstrap/Accordion';
import slugify from 'react-slugify';
import ReactMarkdown from "react-markdown";
import Modal from 'react-bootstrap/Modal';
import Person from './Person';
import ContactForm from '../Components/ContactForm';
import Logo from './../../../assets/img/logo-white.svg';
import FB from './../../../assets/img/icons/facebook.svg';
import LI from './../../../assets/img/icons/linkedin.svg';

const Content = ({id}) => {

    const [content, setContent] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const handleClose = () => setModalShow(false);
    const handleShow = (id) => setModalShow(id);

    const client = createClient({
      space: 'yeka5wqskhix',
      environment: 'master',
      accessToken: 'I48J-PULdPKW0L6xgXE91RDXkZlGjqmQsAand6Ln00k'
    })
  
    useEffect (() => {
      const getContent = async () => {
        try {
          await client.getEntry(id, {locale: 'en'}).then((entries) => {
            setContent(entries);
             // console.log(entries);
          })
        } catch(error) {
          console.log(error);
        }
  
      }
      getContent();
  
    }, [])
  
    if (!content) {
      return "Loading...";
    }

    const renderOptions = {
      renderNode: {
        [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
          // Accordion (or FAQ)
          if (node?.data?.target?.sys?.contentType?.sys?.id === "faq") {

            return (
              <>
              {node?.data?.target?.fields?.type ? 
                <div className="faq-block">
                <Accordion className="faq-wrapper bg-light" flush>
                  
                  <Accordion.Item className="faq-single mb-2" itemScope itemProp="mainEntity" itemType="https://schema.org/Question" eventKey={node?.data?.target?.sys?.id}>

                    <Accordion.Header className="faqtitle" itemProp="name">{node?.data?.target?.fields?.frage}</Accordion.Header>

                    <Accordion.Body id={node?.data?.target?.sys?.id} className="faqanswer" itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                      <div itemProp="text" className="text-lg">{node?.data?.target?.fields?.antwort}</div>
                    </Accordion.Body>

                  </Accordion.Item>
                  
                </Accordion>
                </div>
              : 
                <div className="accordion-block">
                <Accordion flush className="bg-light">
                  
                  <Accordion.Item className="mb-2" eventKey={node?.data?.target?.sys?.id}>

                    <Accordion.Header>{node?.data?.target?.fields?.frage}</Accordion.Header>

                    <Accordion.Body id={node?.data?.target?.sys?.id}>{node?.data?.target?.fields?.antwort}</Accordion.Body>

                  </Accordion.Item>
                  
                </Accordion>
                </div> 
              }
              </>
            );
          }
          // Video
          if (node?.data?.target?.sys?.contentType?.sys?.id === "videoEmbed") {
            return (
              <iframe
                src={node?.data?.target?.fields?.embedUrl}
                height="100%"
                width="100%"
                frameBorder="0"
                scrolling="no"
                title={node?.data?.target?.fields?.title}
                allowFullScreen={true}
                style={{aspectRatio: "16/9"}}
                className='videoframe'
              />
            );
          }
          // Brand
          if (node?.data?.target?.sys?.contentType?.sys?.id === "componentBrand") {
            return (
              <>
              <div className="brand-block col-auto">
                <div id={slugify(node?.data?.target?.fields?.headline)} className='d-flex flex-column flex-md-row p-2 align-items-stretch'>
                { node?.data?.target?.fields?.logo ?
                  <div 
                  onClick={() => handleShow(node?.data?.target?.sys?.id)}
                  className="brand-logo col-12 d-flex justify-content-center align-items-center p-2" 
                  style={node?.data?.target?.fields?.logoBackground ? {background: '#' + node?.data?.target?.fields?.logoBackground} : null}>
                  <img 
                  src={node?.data?.target?.fields?.logo?.fields?.file?.url + '?w=100'} 
                  alt={node?.data?.target?.fields?.file?.title}
                  className="mb-0"></img>
                  </div>
                  : null }
                  <Modal 
                  show={modalShow === node?.data?.target?.sys?.id} 
                  onHide={handleClose}
                  size="md"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  key={node?.data?.target?.sys?.id}
                  >
                    <Modal.Header closeButton className="bg-light border-0 text-white">
                      <Modal.Title id="contained-modal-title-vcenter">
                        {node?.data?.target?.fields?.title}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-4">
                    {documentToReactComponents(node?.data?.target?.fields?.description)}
                    {node?.data?.target?.fields?.button ? 
                      <div className="d-flex mt-2 mt-md-0 col-12 align-items-end">
                        <a 
                          className="asp-btn btn btn-primary bg-hover-secondary text-white text-hover-black rounded-20 rounded-bottom-0 border-0 py-2 py-md-3 px-5 min-h-45px w-auto d-flex align-items-center justify-content-center" 
                          target="_blank"
                          rel="noopener noreferrer"
                          href={node?.data?.target?.fields?.button[1] ? node?.data?.target?.fields?.button[1] : '#'}>{node?.data?.target?.fields?.button[0]}
                        </a>
                      </div>
                      : null }
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
              </>
            );
          }
          // Text Block
          if (node?.data?.target?.sys?.contentType?.sys?.id === "componentTextBlock") {
            const renderNestedOptions = {
              renderNode: {
                [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
                  // render the EMBEDDED_ASSET as you need
                  if (node?.data?.target?.fields?.file?.contentType === 'video/mp4') {
                    return (
                      <iframe
                        src={node?.data?.target?.fields?.file?.url}
                        height="100%"
                        width="100%"
                        frameBorder="0"
                        scrolling="no"
                        title={node.data.target.fields.title}
                        allowFullScreen={true}
                        style={{aspectRatio: "16/9"}}
                        className='videoframe'
                      />
                    );
                  };
                  return (
                    <>
                    <img
                      src={`https://${node?.data?.target?.fields?.file?.url}`}
                      height={node?.data?.target?.fields?.file?.details?.image?.height}
                      width={node?.data?.target?.fields?.file?.details?.image?.width}
                      alt={node?.data?.target?.fields?.title}
                    />
                    <div className="fst-italic fs-8 mb-3 text-muted">{node?.data?.target?.fields?.description}</div>
                    </>
                  );
                }
              }
            }
            return (
              <div className="row gx-0 gx-md-5 flex-column flex-md-row my-4 block-text-wrapper">
              <div className="col-12 block-text-inner">
                {node?.data?.target?.fields?.title ? 
                  <h3 id={slugify(node?.data?.target?.fields?.title)} className="mb-4 block-type-heading lh-1">{node?.data?.target?.fields?.title}</h3>
                : null }
                <div className={node?.data?.target?.fields?.contentListStyle === 'default' ? 'block-text mt-3' : node?.data?.target?.fields?.contentListStyle === 'check' ? 'ca-list list-check block-text mt-3' : node?.data?.target?.fields?.contentListStyle === 'number' ? 'ca-list list-number block-text mt-3': 'block-text mt-3'}>
                  {documentToReactComponents(node?.data?.target?.fields?.content, renderNestedOptions)}
                </div>
              </div>
            </div>
            );
          }
          // Person
          if (node?.data?.target?.sys?.contentType?.sys?.id === "componentPerson") {
            const renderNestedOptions = {
              renderNode: {
                [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
                  return (
                    <>
                    <img
                      src={`https://${node?.data?.target?.fields?.file?.url}`}
                      height={node?.data?.target?.fields?.file?.details?.image?.height}
                      width={node?.data?.target?.fields?.file?.details?.image?.width}
                      alt={node?.data?.target?.fields?.name}
                    />
                    <div className="fst-italic fs-8 mb-3 text-muted">{node?.data?.target?.fields?.description}</div>
                    </>
                  );
                }
              }
            }
            return (
              <div className="row gx-0 gx-md-2 gx-lg-4 gx-xl-5 flex-column flex-md-row-reverse my-5 block-person-wrapper">
                <div className="col-12 col-md-5 block-person-inner row gx-0 gx-md-3 px-md-4 align-items-start align-items-md-center  justify-content-between justify-content-lg-start">
                  <Person
                  image={node?.data?.target?.fields?.image ? node?.data?.target?.fields?.image : ''} 
                  name={node?.data?.target?.fields?.name ? node?.data?.target?.fields?.name : ''} 
                  position={node?.data?.target?.fields?.position ? node?.data?.target?.fields?.position : ''} 
                  company={node?.data?.target?.fields?.company ? node?.data?.target?.fields?.company : ''} 
                  contact={node?.data?.target?.fields?.contact ? node?.data?.target?.fields?.contact : ''}
                  ></Person>
                </div>
                <div className="col-12 col-md-7 block-person-inner mt-4 mt-md-0">
                {node?.data?.target?.fields?.description ? 
                  <div className="bg-primary-25 rounded-20 rounded-bottom-0 border-0 p-0 p-md-5 block-person-inner-text">
                    <ReactMarkdown>{node?.data?.target?.fields?.description}</ReactMarkdown>
                  </div>
                : null }
                </div>
                    {documentToReactComponents(node?.data?.target?.fields?.content, renderNestedOptions)}
              </div>
            );
          }
          // Image with Text Split
          if (node?.data?.target?.sys?.contentType?.sys?.id === "componentImageText") {
            const renderNestedOptions = {
              renderNode: {
                [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
                  return (
                    <>
                    <img
                      src={`https://${node?.data?.target?.fields?.file?.url}`}
                      height={node?.data?.target?.fields?.file?.details?.image?.height}
                      width={node?.data?.target?.fields?.file?.details?.image?.width}
                      alt={node?.data?.target?.fields?.name}
                    />
                    <div className="fst-italic fs-8 mb-3 text-muted">{node?.data?.target?.fields?.description}</div>
                    </>
                  );
                }
              }
            }
            return (
              <div className={
                node?.data?.target?.fields?.order === 'normal' ? 'row gx-0 gx-md-4 gx-xl-5 flex-column flex-md-row my-5 block-image-text-wrapper' 
                : node?.data?.target?.fields?.order === 'reverse' ? 'row gy-4 gy-md-0 gx-0 gx-md-4 gx-xl-5 flex-column-reverse flex-md-row-reverse my-5 block-image-text-wrapper'
                : node?.data?.target?.fields?.order === 'normal-with-mobile-reverse' ? 'row gy-4 gy-md-0 gx-0 gx-md-4 gx-xl-5 flex-column-reverse flex-md-row my-5 block-image-text-wrapper' 
                : node?.data?.target?.fields?.order === 'reverse-with-mobile-normal' ? 'row gx-0 gx-md-4 gx-xl-5 flex-column flex-md-row-reverse my-5 block-image-text-wrapper' 
                : 'row gx-0 gx-md-4 gx-xl-5 flex-column flex-md-row my-5 block-image-text-wrapper'
                }>
                <div className="col-12 col-md-6">
                    <img src={node?.data?.target?.fields?.image ? node?.data?.target?.fields?.image.fields?.file?.url + '?w=600' : ''} 
                          className="rounded-20 rounded-bottom-0"
                          width={600}
                          height={600}
                          alt={''}>
                    </img>
                </div>
                <div className="col-12 col-md-6 block-image-text-inner mt-4 mt-md-0">
                {node?.data?.target?.fields?.text ? 
                  <div className="col-12 p-0 block-image-text-inner-text">
                    <ReactMarkdown>{node?.data?.target?.fields?.text}</ReactMarkdown>
                  </div>
                : null }
                <div className="col-12 mt-4 d-flex justify-content-end justify-content-sm-start">
                  { node?.data?.target?.fields?.button ? 
                    <a href={node?.data?.target?.fields?.button[1] ? node?.data?.target?.fields?.button[1] : ''} className="asp-btn btn btn-primary bg-hover-secondary text-white text-hover-black rounded-20 rounded-bottom-0 border-0 p-2 px-3 p-md-3 px-md-4 min-h-45px w-auto d-flex align-items-center justify-content-center" role="button">
                      {node?.data?.target?.fields?.button[0] ? <span>{node?.data?.target?.fields?.button[0]}</span> : null}
                    </a>
                  : null }
                  </div>
                </div>
                    {documentToReactComponents(node?.data?.target?.fields?.content, renderNestedOptions)}
              </div>
            );
          }
          // Contact Block
          if (node?.data?.target?.sys?.contentType?.sys?.id === "componentContactBlock") {
            return (
              <div className="contact-block container-fluid mt-5">
                <div className="container p-2 p-md-3 p-lg-5 bg-secondary-25 rounded-20 rounded-bottom-0">
                  <div className="row px-1 px-md-3 px-lg-5 flex-column flex-sm-row-reverse d-flex justify-content-sm-center mt-3">
                    <div className="col-12 col-md-6 mb-4 mb-md-0">
                      <ContactForm 
                      fsn={node?.data?.target?.fields?.fsn ? node?.data?.target?.fields?.fsn : ''}
                      spbf={node?.data?.target?.fields?.spbf ? node?.data?.target?.fields?.spbf : ''}
                      sbt={node?.data?.target?.fields?.submitButtonText ? node?.data?.target?.fields?.spbf : 'submit'}> 
                      </ContactForm>
                    </div>
                    <div className="col-12 col-md-6">
                      {node?.data?.target?.fields?.title ?
                      <h4 className="mb-4">{node?.data?.target?.fields?.title}</h4>
                      : null }
                      <img src={Logo} alt="Aspire Education Logo" width="160" height="36" ></img>
                      {node?.data?.target?.fields?.address ?
                      <div className="mt-4 mb-5 contact-address"><ReactMarkdown>{node?.data?.target?.fields?.address}</ReactMarkdown></div>
                      : null}
                      {node?.data?.target?.fields?.contactPersonName ?
                      <div className="col-12 block-person-inner row align-items-center mt-4 mb-5">
                      <Person
                      image={node?.data?.target?.fields?.contactPersonImage ? node?.data?.target?.fields?.contactPersonImage : ''} 
                      name={node?.data?.target?.fields?.contactPersonName ? node?.data?.target?.fields?.contactPersonName : ''} 
                      position={node?.data?.target?.fields?.contactPersonPosition ? node?.data?.target?.fields?.contactPersonPosition : ''} 
                      company={node?.data?.target?.fields?.contactPersonCompany ? node?.data?.target?.fields?.contactPersonCompany : ''} 
                      contact={''}
                      ></Person>
                      </div>
                      : null}
                      <h4 className="mb-2">Social media</h4>
                      {node?.data?.target?.fields?.facebook ?
                      <a href={node?.data?.target?.fields?.facebook} target="_blank" rel="nofollow noreferrer">
                      <img src={FB} alt="Facebook Logo" width="33" height="33" ></img>
                      </a>
                      : null}
                      {node?.data?.target?.fields?.linkedin ?
                      <a href={node?.data?.target?.fields?.linkedin}  target="_blank" rel="nofollow noreferrer">
                      <img src={LI} alt="Linkedin Logo" width="35" height="35" ></img>
                      </a>
                      : null}
                    </div>
                  </div>
                </div>
              </div>
              );
          }
        },

        // If the node is a link
        [INLINES.HYPERLINK]: (node, children) => {
          // Only process youtube links
          if (node.data.uri.includes("youtube.com")) {
              // Extract videoId from the URL
              const match = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/.exec(
                  node.data.uri
              )
              const videoId =
                  match && match[7].length === 11 ? match[7] : null
              return (
                  videoId && (
                  <span className="video-container d-block">
                      <iframe
                          className="video"
                          title={`https://youtube.com/embed/${videoId}`}
                          src={`https://youtube.com/embed/${videoId}`}
                          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                          frameBorder="0"
                          allowFullScreen
                      />
                  </span>
                  )
                )
          }
          else {
            return(
            <a
              href={node.data.uri}
              target={`${node.data.uri.startsWith(window.location.origin) ? '_self' : '_blank'}`}
              rel={`${node.data.uri.startsWith(window.location.origin) ? '' : 'noopener noreferrer'}`}
            >{children}</a>
            )
          }
        },
    
        [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
          // render the EMBEDDED_ASSET as you need
          if (node?.data?.target?.fields?.file?.contentType === 'video/mp4') {
            return (
              <iframe
                src={node?.data?.target?.fields?.file?.url}
                height="100%"
                width="100%"
                frameBorder="0"
                scrolling="no"
                title={node.data.target.fields.title}
                allowFullScreen={true}
                style={{aspectRatio: "16/9"}}
                className='videoframe'
              />
            );
          };
          return (
            <>
            <img
              src={`https://${node?.data?.target?.fields?.file?.url}`}
              height={node?.data?.target?.fields?.file?.details?.image?.height}
              width={node?.data?.target?.fields?.file?.details?.image?.width}
              alt={node?.data?.target?.fields?.title}
            />
            <div className="fst-italic fs-8 mb-3 text-muted">{node?.data?.target?.fields?.description}</div>
            </>
          );
        },
        [BLOCKS.HEADING_1]: (node, children) => {
          return (
            <span className="block-type-heading d-block lh-1 w-100"><h1>{children}</h1></span>
          );
         },
         [BLOCKS.HEADING_2]: (node, children) => {
          return (
            <span id={slugify(children[0]?.props?.children ? children[0]?.props?.children : children)} className="block-type-heading d-block lh-1 w-100"><h2>{children}</h2></span>
          );
         },
         [BLOCKS.HEADING_3]: (node, children) => {
          return (
            <span id={slugify(children[0]?.props?.children ? children[0]?.props?.children : children)} className="d-block w-100"><h3>{children}</h3></span>
          );
         },
         [BLOCKS.HEADING_4]: (node, children) => {
          return (
            <span className="block-type-heading d-block lh-1 w-100"><h4>{children}</h4></span>
          );
         },
         [BLOCKS.HEADING_5]: (node, children) => {
          return (
            <span className="block-type-heading d-block lh-1 w-100"><h5>{children}</h5></span>
          );
         },
         [BLOCKS.HEADING_6]: (node, children) => {
          return (
            <span className="block-type-heading d-block lh-1 w-100"><h6>{children}</h6></span>
          );
         },

      },
    };

  return (
    <article id="article" className='main-article mt-3 mt-md-0'>
      {documentToReactComponents(content?.fields?.content, renderOptions)}
    </article>
  );
}

export default Content;