const Footer= () => {

  const today = new Date();
  const year = today.getFullYear();

  return (
  <footer className="footerclass bg-primary-25 py-3 py-md-5 mt-5">
    <div className="container p-0">
      <div className="row gx-0 gx-md-3 flex-column flex-sm-row d-flex align-items-end justify-content-sm-center mt-3 px-3 px-md-0">
        <div className="col-12 col-md-6 d-flex flex-column">
          <p className="fw-bold">Übersicht</p>
          <a href="/impressum" className="text-dark">Impressum</a>
          <a href="/datenschutzerklaerung" className="text-dark">Datenschutzerklärung</a>
          <a 
            href="https://app.loupe.link/whistleblowing/87634eb5-526c-436d-b810-4ffb620d070b" 
            target="_blank" 
            rel="nofollow noreferrer"
            className="text-dark">Whistleblower Portal
          </a>
          <a href="#CCM.openWidget" className="text-dark">Datenschutz-Einstellungen</a>
        </div>
        <div className="col-12 col-md-6 d-flex justify-content-end mt-3 mt-md-0 fw-bold">©{year} aspire education GmbH</div>
      </div>
    </div>
  </footer>
  );
}

export default Footer;